import React from 'react';

function App() {
    return (
        <div className="App">
            <h1>Welcome to the Quest System</h1>
            <p>Your journey begins here!</p>
        </div>
    );
}

export default App;
